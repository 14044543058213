import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link, useLocation } from "react-router-dom";
import { Link as Scroll } from "react-scroll";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const { pathname, hash } = useLocation();
  return (
    <footer className="bg-secondary-100">
      <div className="padding pb-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 md:gap-0">
          <div className="">
            <img src="images/atrolegal-logo.png" alt="" />
            {/* <ul className="flex items-center gap-8 mt-3">
              <li>
                <a href="">
                  <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-primary-100"
                  >
                    <g clipPath="url(#clip0_33886_5166)">
                      <path
                        d="M1.78645 14V7.43079H-0.000488281V5.06556H1.78645V3.04535C1.78645 1.45785 2.842 0 5.27422 0C6.25898 0 6.98717 0.09177 6.98717 0.09177L6.92979 2.30049C6.92979 2.30049 6.18716 2.29347 5.37676 2.29347C4.49967 2.29347 4.35914 2.68638 4.35914 3.33851V5.06556H6.99951L6.88463 7.43079H4.35914V14H1.78645Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_33886_5166">
                        <rect
                          width="7"
                          height="14"
                          fill="white"
                          transform="translate(-0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li>
                <a href="">
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-primary-100"
                  >
                    <path
                      d="M18 1.7049C17.326 1.98937 16.611 2.1761 15.879 2.25887C16.6415 1.82346 17.227 1.13398 17.5027 0.312468C16.7778 0.722276 15.9847 1.01101 15.1577 1.1662C14.484 0.482477 13.5243 0.0551758 12.4621 0.0551758C10.4226 0.0551758 8.76909 1.63041 8.76909 3.57326C8.76909 3.84904 8.80179 4.11752 8.86472 4.37508C5.79558 4.22831 3.07448 2.82771 1.25304 0.699175C0.935227 1.21878 0.753117 1.82319 0.753117 2.46786C0.753117 3.68848 1.40513 4.76527 2.39597 5.39621C1.80953 5.37871 1.23599 5.22781 0.723234 4.95611C0.723023 4.97085 0.723023 4.98559 0.723023 5.00039C0.723023 6.70497 1.99596 8.12694 3.68529 8.45015C3.14148 8.59105 2.57107 8.61167 2.01762 8.51044C2.48752 9.90816 3.85137 10.9253 5.46729 10.9537C4.20342 11.8973 2.61105 12.4598 0.880945 12.4598C0.58282 12.4598 0.288914 12.4431 0 12.4106C1.63427 13.4088 3.57539 13.9913 5.66086 13.9913C12.4535 13.9913 16.1679 8.63034 16.1679 3.98127C16.1679 3.82868 16.1644 3.67695 16.1573 3.5261C16.8802 3.02819 17.5042 2.41147 18 1.7049Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="">
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-primary-100"
                  >
                    <g clipPath="url(#clip0_33886_5158)">
                      <path
                        d="M3.63269 15V4.87953H0.202386V15H3.63269ZM1.91798 3.49692C3.11419 3.49692 3.85877 2.71978 3.85877 1.74862C3.83648 0.755565 3.11423 0 1.94068 0C0.767312 0 0 0.75558 0 1.74862C0 2.71983 0.744398 3.49692 1.89559 3.49692H1.91787H1.91798ZM5.53135 15H8.96165V9.34824C8.96165 9.04576 8.98394 8.7436 9.07452 8.52738C9.32251 7.92304 9.88693 7.29712 10.8345 7.29712C12.0758 7.29712 12.5724 8.2252 12.5724 9.5857V14.9999H16.0025V9.19695C16.0025 6.08834 14.3101 4.64193 12.0532 4.64193C10.2027 4.64193 9.39021 5.65626 8.93883 6.34712H8.96173V4.87932H5.53143C5.57644 5.82897 5.53143 14.9998 5.53143 14.9998L5.53135 15Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_33886_5158">
                        <rect width="16" height="15" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li>
                <a href="">
                  <svg
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-primary-100"
                  >
                    <g clipPath="url(#clip0_33886_5154)">
                      <path
                        d="M8.0038 4.95708C5.73238 4.95708 3.90023 6.76059 3.90023 8.99653C3.90023 11.2325 5.73238 13.036 8.0038 13.036C10.2752 13.036 12.1074 11.2325 12.1074 8.99653C12.1074 6.76059 10.2752 4.95708 8.0038 4.95708ZM8.0038 11.6227C6.53595 11.6227 5.33595 10.445 5.33595 8.99653C5.33595 7.54809 6.53238 6.37036 8.0038 6.37036C9.47523 6.37036 10.6717 7.54809 10.6717 8.99653C10.6717 10.445 9.47166 11.6227 8.0038 11.6227ZM13.2324 4.79184C13.2324 5.31567 12.8038 5.73403 12.2752 5.73403C11.7431 5.73403 11.3181 5.31216 11.3181 4.79184C11.3181 4.27153 11.7467 3.84966 12.2752 3.84966C12.8038 3.84966 13.2324 4.27153 13.2324 4.79184ZM15.9502 5.74809C15.8895 4.48598 15.5967 3.36802 14.6574 2.44692C13.7217 1.52583 12.5859 1.23755 11.3038 1.17427C9.98237 1.10044 6.02166 1.10044 4.70023 1.17427C3.42166 1.23403 2.28595 1.52231 1.34666 2.44341C0.40738 3.3645 0.118094 4.48247 0.0538086 5.74458C-0.0211914 7.04536 -0.0211914 10.9442 0.0538086 12.245C0.114523 13.5071 0.40738 14.625 1.34666 15.5461C2.28595 16.4672 3.41809 16.7555 4.70023 16.8188C6.02166 16.8926 9.98237 16.8926 11.3038 16.8188C12.5859 16.759 13.7217 16.4707 14.6574 15.5461C15.5931 14.625 15.8859 13.5071 15.9502 12.245C16.0252 10.9442 16.0252 7.04887 15.9502 5.74809ZM14.2431 13.6407C13.9645 14.3297 13.4252 14.8606 12.7217 15.1383C11.6681 15.5497 9.16809 15.4547 8.0038 15.4547C6.83952 15.4547 4.33595 15.5461 3.28595 15.1383C2.58595 14.8641 2.04666 14.3332 1.76452 13.6407C1.34666 12.6036 1.44309 10.1426 1.44309 8.99653C1.44309 7.85044 1.35024 5.38598 1.76452 4.35239C2.04309 3.66333 2.58238 3.13247 3.28595 2.85474C4.33952 2.44341 6.83952 2.53833 8.0038 2.53833C9.16809 2.53833 11.6717 2.44692 12.7217 2.85474C13.4217 3.12895 13.9609 3.65981 14.2431 4.35239C14.6609 5.3895 14.5645 7.85044 14.5645 8.99653C14.5645 10.1426 14.6609 12.6071 14.2431 13.6407Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_33886_5154">
                        <rect width="16" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li>
                <a href="">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-primary-100"
                  >
                    <path
                      d="M9.00739 0C4.03294 0 0 4.02632 0 8.9926C0 12.8043 2.37232 16.0609 5.72322 17.3709C5.64167 16.6604 5.57495 15.565 5.75287 14.7878C5.91597 14.0847 6.80559 10.3174 6.80559 10.3174C6.80559 10.3174 6.5387 9.77714 6.5387 8.9852C6.5387 7.73438 7.26522 6.80181 8.16967 6.80181C8.94067 6.80181 9.31135 7.37911 9.31135 8.06744C9.31135 8.83717 8.82206 9.99178 8.56258 11.065C8.34759 11.9605 9.01481 12.6933 9.89701 12.6933C11.4983 12.6933 12.729 11.0058 12.729 8.57813C12.729 6.42434 11.1795 4.92188 8.96291 4.92188C6.39784 4.92188 4.8929 6.83882 4.8929 8.82237C4.8929 9.59211 5.18944 10.4211 5.56012 10.8725C5.63425 10.9613 5.64167 11.0428 5.61943 11.1316C5.55271 11.4128 5.39702 12.0271 5.36737 12.153C5.3303 12.3158 5.23393 12.3528 5.06342 12.2714C3.93656 11.7459 3.23228 10.1102 3.23228 8.78536C3.23228 5.95066 5.29323 3.3454 9.18532 3.3454C12.3064 3.3454 14.738 5.56579 14.738 8.54112C14.738 11.6423 12.7809 14.1365 10.0675 14.1365C9.15566 14.1365 8.2957 13.6628 8.00657 13.1003C8.00657 13.1003 7.55435 14.8174 7.44315 15.2393C7.24298 16.0238 6.69438 17.0008 6.32371 17.6003C7.16885 17.8594 8.05847 18 8.99257 18C13.967 18 18 13.9737 18 9.0074C18.0148 4.02632 13.9818 0 9.00739 0Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </li>
            </ul> */}
          </div>
          <div className="grid md:place-items-center">
            <ul className="flex gap-4 flex-col">
              <li>
                <a href="/">{t("header.home")}</a>
              </li>
              <li>
                <a href="/#service">{t("header.service")}</a>
              </li>
              <li>
                <a href="/trademarks">{t("header.trademarks")}</a>
              </li>
            </ul>
          </div>
          <div className="grid md:place-items-center">
            <ul className="flex gap-4 flex-col">
              <li>
                <a href="/patents">{t("header.patents")}</a>
              </li>
              <li>
                <a href="/contract-agreement">{t("header.contracts/agreements")}</a>
              </li>
              {/* <li>
                <a href="/">{t("header.legal")}</a>
              </li> */}
            </ul>
          </div>
          <div className="flex md:justify-center">
            <ul className="flex gap-4 flex-col">
              <li className="flex items-center gap-3">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-primary-100"
                >
                  <path
                    d="M6.45059 7.29866C7.14659 8.74828 8.09537 10.1069 9.29695 11.3085C10.4985 12.5101 11.8572 13.4589 13.3068 14.1549C13.4315 14.2147 13.4938 14.2447 13.5727 14.2677C13.8531 14.3494 14.1973 14.2907 14.4348 14.1207C14.5016 14.0728 14.5587 14.0157 14.673 13.9014C15.0226 13.5518 15.1975 13.377 15.3732 13.2627C16.0361 12.8317 16.8907 12.8317 17.5536 13.2627C17.7294 13.377 17.9042 13.5518 18.2538 13.9014L18.4486 14.0962C18.9801 14.6277 19.2458 14.8934 19.3902 15.1788C19.6772 15.7463 19.6772 16.4166 19.3902 16.9842C19.2458 17.2695 18.9801 17.5353 18.4486 18.0667L18.291 18.2243C17.7614 18.754 17.4966 19.0188 17.1365 19.221C16.737 19.4455 16.1165 19.6068 15.6583 19.6054C15.2454 19.6042 14.9632 19.5241 14.3987 19.3639C11.3653 18.5029 8.50295 16.8785 6.11497 14.4905C3.72699 12.1025 2.10252 9.24014 1.24155 6.20675C1.08134 5.6423 1.00124 5.36008 1.00001 4.94713C0.998645 4.48891 1.16001 3.86842 1.38443 3.46891C1.58668 3.10888 1.85149 2.84407 2.38111 2.31445L2.53874 2.15681C3.07019 1.62537 3.33591 1.35965 3.62129 1.2153C4.18885 0.928232 4.85912 0.928232 5.42668 1.2153C5.71206 1.35965 5.97778 1.62537 6.50922 2.15681L6.70409 2.35168C7.0537 2.70129 7.2285 2.87609 7.34279 3.05187C7.77378 3.71476 7.77378 4.56934 7.34278 5.23223C7.2285 5.40801 7.0537 5.58281 6.70409 5.93242C6.58978 6.04673 6.53262 6.10388 6.48478 6.1707C6.31477 6.40813 6.25607 6.75239 6.33779 7.03274C6.36079 7.11163 6.39072 7.17397 6.45059 7.29866Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                + 39 3516369069
              </li>
              <li className="flex items-center gap-3">
                <svg
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-primary-100"
                >
                  <path
                    d="M1 4L6.19384 7.46256C6.49349 7.66232 6.64331 7.7622 6.8054 7.83296C6.94927 7.89577 7.10003 7.94142 7.25458 7.96896C7.42869 8 7.60876 8 7.96888 8H14.0311C14.3912 8 14.5713 8 14.7454 7.96896C14.9 7.94142 15.0507 7.89577 15.1946 7.83296C15.3567 7.76221 15.5065 7.66232 15.8062 7.46256L21 4M5.8 17H16.2C17.8802 17 18.7202 17 19.362 16.673C19.9265 16.3854 20.3854 15.9265 20.673 15.362C21 14.7202 21 13.8802 21 12.2V5.8C21 4.11984 21 3.27976 20.673 2.63803C20.3854 2.07354 19.9265 1.6146 19.362 1.32698C18.7202 1 17.8802 1 16.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V12.2C1 13.8802 1 14.7202 1.32698 15.362C1.6146 15.9265 2.07354 16.3854 2.63803 16.673C3.27976 17 4.11984 17 5.8 17Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                info@astrolegal.net
              </li>
            </ul>
          </div>
        </div>

        <div className="flex items-center flex-col md:flex-row justify-between border-t py-4 border-grey-200 mt-[100px]">
          <p>Copyright © 2023 AstroLegal. All rights reserved.</p>
          <div className="md:ml-auto flex items-center gap-3">
            <p>
              <a href="privacy-policy.pdf" target="_blank" rel="noopener noreferrer" className="hover:text-black transition duration-300 ease-in-out">Privacy Policy</a>
            </p>
            {/* <p>|</p>
            <p>
              <a href="">Press</a>
            </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
