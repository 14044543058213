import { useTranslation } from "react-i18next";

const TrademarksPlans = () => {
  const { t } = useTranslation();

  return (
    <section className="py-12 px-6 bg-gray-100">
      <h2 className="text-3xl font-bold text-center mb-8">{t("trademarks.section-4.title")}</h2>
      <div className="max-w-4xl mx-auto space-y-8">
        
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-2xl font-semibold mb-4">{t("trademarks.section-4.block-1.title")}</h3>
          {/* <p className="text-gray-700 mb-4">{t("trademarks.section-4.block-1.description")}</p> */}
          <div className="flex justify-between items-center">
            <span className="text-blue-500 text-2xl">€1290,00</span>
            <button className="bg-[#F8AD53] text-black inline-flex items-center gap-2 hover:bg-[#F8AD53]/90 border-4 border-orange-100 px-4 py-2 rounded">
              {t("trademarks.section-4.buy_now")}
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="black">
                <path d="M0 7.72564C0 7.34595 0.282154 7.03215 0.648229 6.98249L0.75 6.97564L15.75 6.97564C16.1642 6.97564 16.5 7.31143 16.5 7.72564C16.5 8.10534 16.2178 8.41913 15.8518 8.4688L15.75 8.47564L0.75 8.47564C0.335786 8.47564 0 8.13986 0 7.72564Z" fill="currentColor"></path>
                <path d="M9.17101 2.23271C8.87749 1.94045 8.87646 1.46558 9.16872 1.17206C9.43442 0.905218 9.85103 0.880116 10.1451 1.09734L10.2294 1.16977L16.2794 7.19377C16.547 7.46026 16.5714 7.87837 16.3524 8.17245L16.2794 8.25667L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.5959 9.09817 13.3028L9.17096 13.2188L14.687 7.72494L9.17101 2.23271Z" fill="currentColor"></path>
              </svg>
            </button>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-2xl font-semibold mb-4">{t("trademarks.section-4.block-2.title")}</h3>
          {/* <p className="text-gray-700 mb-4">{t("trademarks.section-4.block-2.description")}</p> */}
          <div className="flex justify-between items-center">
            <span className="text-blue-500 text-2xl">€1440,00</span>
            <button className="bg-[#F8AD53] text-black inline-flex items-center gap-2 hover:bg-[#F8AD53]/90 border-4 border-orange-100 px-4 py-2 rounded">
              {t("trademarks.section-4.buy_now")}
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="black">
                <path d="M0 7.72564C0 7.34595 0.282154 7.03215 0.648229 6.98249L0.75 6.97564L15.75 6.97564C16.1642 6.97564 16.5 7.31143 16.5 7.72564C16.5 8.10534 16.2178 8.41913 15.8518 8.4688L15.75 8.47564L0.75 8.47564C0.335786 8.47564 0 8.13986 0 7.72564Z" fill="currentColor"></path>
                <path d="M9.17101 2.23271C8.87749 1.94045 8.87646 1.46558 9.16872 1.17206C9.43442 0.905218 9.85103 0.880116 10.1451 1.09734L10.2294 1.16977L16.2794 7.19377C16.547 7.46026 16.5714 7.87837 16.3524 8.17245L16.2794 8.25667L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.5959 9.09817 13.3028L9.17096 13.2188L14.687 7.72494L9.17101 2.23271Z" fill="currentColor"></path>
              </svg>
            </button>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-2xl font-semibold mb-4">{t("trademarks.section-4.block-3.title")}</h3>
          {/* <p className="text-gray-700 mb-4">{t("trademarks.section-4.block-3.description")}</p> */}
          <div className="flex justify-between items-center">
            <span className="text-blue-500 text-2xl">€1590,00</span>
            <button className="bg-[#F8AD53] text-black inline-flex items-center gap-2 hover:bg-[#F8AD53]/90 border-4 border-orange-100 px-4 py-2 rounded">
              {t("trademarks.section-4.buy_now")}
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="black">
                <path d="M0 7.72564C0 7.34595 0.282154 7.03215 0.648229 6.98249L0.75 6.97564L15.75 6.97564C16.1642 6.97564 16.5 7.31143 16.5 7.72564C16.5 8.10534 16.2178 8.41913 15.8518 8.4688L15.75 8.47564L0.75 8.47564C0.335786 8.47564 0 8.13986 0 7.72564Z" fill="currentColor"></path>
                <path d="M9.17101 2.23271C8.87749 1.94045 8.87646 1.46558 9.16872 1.17206C9.43442 0.905218 9.85103 0.880116 10.1451 1.09734L10.2294 1.16977L16.2794 7.19377C16.547 7.46026 16.5714 7.87837 16.3524 8.17245L16.2794 8.25667L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.5959 9.09817 13.3028L9.17096 13.2188L14.687 7.72494L9.17101 2.23271Z" fill="currentColor"></path>
              </svg>
            </button>
          </div>
        </div>

      </div>
    </section>
  );
};

export default TrademarksPlans;
