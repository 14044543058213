import React from "react";
import { useTranslation } from "react-i18next";

const Label = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="bg-primary-100 mt-10">
      <div className="flex flex-col md:flex-row items-center justify-between text-white padding py-11 gap-6">
        <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-center md:text-left">
          {t("label.text")}
        </h2>
        {/* <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center md:text-left">
          {t("label.text")}
        </h2> */}
        <a
          href="mailto:info@astrolegal.net"
          className="btn bg-white flex items-center gap-2 hover:bg-white/90"
        >
          {t("label.btn")}
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-primary-100"
          >
            <path
              d="M0 7.72564C0 7.34595 0.282154 7.03215 0.648229 6.98249L0.75 6.97564L15.75 6.97564C16.1642 6.97564 16.5 7.31143 16.5 7.72564C16.5 8.10534 16.2178 8.41913 15.8518 8.4688L15.75 8.47564L0.75 8.47564C0.335786 8.47564 0 8.13986 0 7.72564Z"
              fill="currentColor"
            />
            <path
              d="M9.17101 2.23271C8.87749 1.94045 8.87646 1.46558 9.16872 1.17206C9.43442 0.905218 9.85103 0.880116 10.1451 1.09734L10.2294 1.16977L16.2794 7.19377C16.547 7.46026 16.5714 7.87837 16.3524 8.17245L16.2794 8.25667L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.5959 9.09817 13.3028L9.17096 13.2188L14.687 7.72494L9.17101 2.23271Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
    </section>
  );
};

export default Label;
