import Hero from "../components/hero";
import Layout from "../layouts";
import Information from "../components/information";
import TrademarksProgress from "../sections/trademarks/trademarksProgress";
import TrademarksPlans from "../sections/trademarks/trademarksPlans";
import { useTranslation } from "react-i18next";

const Trademarks = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Hero
        title="trademarks.section-1.title"
        subtitle="trademarks.section-1.description"
        image="/images/hero-trademarks.png"
      />
      <Information
        title={<>{t("trademarks.section-2.title")}</>}
        paragraph={
          <>
            <p>{t("trademarks.section-2.p-1")}</p>
            <p>{t("trademarks.section-2.p-2")}</p>
          </>
        }
      />
      <TrademarksProgress />
      <TrademarksPlans />
    </Layout>
  );
};

export default Trademarks;
