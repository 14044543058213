const Companies = () => {
  return (
    <section className="padding">
      <div className="flex flex-col md:flex-row justify-between gap-4 items-center border border-primary-100 py-10 mb-10 px-6 flex-wrap">
        <img
          src="/images/astro-company-5.png"
          alt=""
          className="object-cover h-full"
        />
        <img
          src="/images/astro-company-1.png"
          alt=""
          className="object-cover h-full"
        />
        <img
          src="/images/astro-company-2.png"
          alt=""
          className="object-cover h-full"
        />
        <img
          src="/images/astro-company-3.png"
          alt=""
          className="object-cover h-full"
        />
        <img
          src="/images/astro-company-4.png"
          alt=""
          className="object-cover h-full"
        />
      </div>
    </section>
  );
};

export default Companies;
