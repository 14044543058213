import Hero from "../components/hero";
import Layout from "../layouts";
import Information from "../components/information";
import PatentsProgress from "../sections/patents/patentsProgress";
import PatentsPlans from "../sections/patents/patentsPlans";
import { useTranslation } from "react-i18next";

const Patents = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Hero
        title="patents.section-1.title"
        subtitle="patents.section-1.description"
        image="/images/hero-patents.png"
      />
      <Information
        title={<>{t("patents.section-2.title")}</>}
        paragraph={
          <>
            <p>{t("patents.section-2.p-1")}</p>
            <p>{t("patents.section-2.p-2")}</p>
          </>
        }
      />
      <PatentsProgress />
      <PatentsPlans />
   
    </Layout>
  )
}

// const Patents = () => {
//   return (
//     <Layout>
//       <Hero
//         title="Patent Registration in the European Union"
//         subtitle="We are a top filer of European trademarks in the EU. We offer intellectual property (IP) legal services in Europe including European trademark registrations and related trademark attorney services."
//         image="/images/patents-hero.png"
//       />
//       <Information
//         title={
//           <>
//             European Patent <br /> Registration
//           </>
//         }
//         paragraph={
//           <>
//             <p>
//               We are a top filer of European trademarks in the EU. We offer
//               intellectual property (IP) legal services in Europe including
//               European trademark registrations and related trademark attorney
//               services. As EU trademark agents and European trademark lawyers we
//               offer an uncomplicated, personal, non-technical registration
//               process.
//             </p>
//             <p>
//               Our biggest advantages are the quick turn-around time and a high
//               success rate. We also value frankness with clients, personal
//               communication, clear pricing and low fees. At least this is what
//               sets us apart on the European IP market. We have a clear pricing
//               model, we always try to anticipate costs exactly. We hope that our
//               clients will return to us with newer and newer branding ideas.
//             </p>
//           </>
//         }
//       />
//       <TrademarksProgress />
//       {/* <TrademarksPlans /> */}
//     </Layout>
//   );
// };

export default Patents;
