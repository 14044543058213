import { useTranslation } from "react-i18next";

const Perche = () => {
  const { t } = useTranslation();

  const cards = [
    {
      image: "/images/icon-processo.png",
      title: "perche.card-one.title",
      subtitle: "perche.card-one.subtitle",
    },
    {
      image: "/images/icon-perche-2.png",
      title: "perche.card-two.title",
      subtitle: "perche.card-two.subtitle",
    },
    {
      image: "/images/icon-perche-3.png",
      title: "perche.card-three.title",
      subtitle: "perche.card-three.subtitle",
    },
    {
      image: "/images/icon-perche-4.png",
      title: "perche.card-four.title",
      subtitle: "perche.card-four.subtitle",
    },
  ];

  return (
    <section className="bg-grey-100">
      <div className="padding gap-4 flex flex-col">
        <h3 className="mx-auto">{t("perche.title")}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-16">
          {cards.map((item, index) => (
            <div
              className="border border-primary-100 py-8 px-8 space-y-3"
              key={index}
            >
              <img src={item.image} alt="icon" />
              <p className="text-black font-bold">{t(item.title)}</p>
              <p>{t(item.subtitle)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Perche;
