import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import i18next from "i18next";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [isOffsetOpen, setIsOffsetOpen] = useState(false);
  const toggleOffset = () => {
    setIsOffsetOpen(!isOffsetOpen);
  };
  const { t, i18n } = useTranslation();
  const { pathname, hash } = useLocation();
  const countries = [
    {
      label: "Italy",
      src: "flags/it.png",
      link: " ",
      value: "IT",
    },
    {
      label: "English",
      src: "flags/en.png",
      link: " ",
      value: "EN",
    },
  ];

  const selectedFlag = i18n.language;

  const [country, setCountry] = React.useState(`flags/${selectedFlag}.png`);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setCountry(event.target.value);

    if (event.target.value === "flags/it.png") {
      return i18next.changeLanguage("it");
    }

    i18next.changeLanguage("en");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const langStyle = {
    menuProps: {
      sx: {
        "&& .MuiList-root": {
          backgroundColor: "#04153f",
        },
      },
    },
    sx: {
      color: "white",
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#1252D3",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1252D3",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1252D3",
      },
      ".MuiSvgIcon-root ": {
        fill: "#1252D3 !important",
        marginTop: "4px",
      },
      borderRadius: "1px",
      padding: "0",
      maxHeight: "52px",
      marginBottom: "8px",
      display: "flex",
      alignItems: "center",
    },
    menuItem: {
      backgroundColor: "#04153f",
      flexShrink: 0,
      display: "flex",
      alignItems: "center",
    },
  };

  const isActive = (menu) => {
    if (menu === pathname) {
      return "nav-item active";
    }

    return "nav-item ";
  };

  useEffect(() => {
    if (hash) {
      let elem = document.getElementById(hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [hash]);

  return (
    <>
      <header className="padding flex items-center justify-between py-6 lg:p-10">
        <a href="">
          <img src="/images/atrolegal-logo.png" alt="Logo" />
        </a>

        <nav className="lg:flex items-center gap-6 hidden ">
          <a className={isActive("/")} href="/">
            {t("header.home")}
          </a>
          <a className="nav-item cursor-pointer" href="/#service">
            {t("header.service")}
          </a>
          <Link className={isActive("/trademarks")} to="/trademarks">
            {t("header.trademarks")}
          </Link>
          <Link className={isActive("/patents")} to="/patents">
            {t("header.patents")}
          </Link>
          <Link
            className={isActive("/contract-agreement")}
            to="/contract-agreement"
          >
            {t("header.contracts/agreements")}
          </Link>
          {/* <Link className="nav-item" to="/">
            {t("header.legal")}
          </Link> */}
          <a href="astrolegal.42pay.io" className="btn">
            {t("header.signin")}
          </a>
          <FormControl sx={{ minWidth: "20px" }}>
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={country}
              name="country"
              onChange={handleChange}
              inputProps={{
                id: "open-select",
              }}
              MenuProps={langStyle.menuProps}
              sx={langStyle.sx}
            >
              {countries.map((option, key) => (
                <MenuItem
                  name={"l"}
                  value={option.src}
                  key={key}
                  sx={langStyle.menuItem}
                >
                  <img
                    width="30px"
                    height="30px"
                    style={{ marginTop: "6px" }}
                    src={option.src}
                    alt={option.label}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </nav>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10 cursor-pointer lg:hidden"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          onClick={toggleOffset}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </header>

      {/* header offset */}
      {isOffsetOpen && (
        <div className="bg-white fixed left-0 top-0 z-[100] w-full min-h-screen h-full padding py-6 overflow-auto xl:hidden">
          <div className="flex items-center justify-between">
            <a href="/" className="mr-8 shrink-0">
              <img src="atrolegal-logo.png" alt="Logo" />
            </a>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-primary-100 cursor-pointer block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              onClick={toggleOffset}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>

          <nav className="flex items-center gap-10 flex-col mt-10">
            <Link className={isActive("/")} to="/">
              {t("header.home")}
            </Link>
            <a className="nav-item cursor-pointer" href="/#service">
              {t("header.service")}
            </a>
            <Link className={isActive("/trademarks")} to="/trademarks">
              {t("header.trademarks")}
            </Link>
            <Link className={isActive("/patents")} to="/patents">
              {t("header.patents")}
            </Link>
            <Link
              className={isActive("/contract-agreement")}
              to="/contract-agreement"
            >
              {t("header.contracts/agreements")}
            </Link>
            <Link className="nav-item" to="/">
              {t("header.legal")}
            </Link>

            <FormControl sx={{ minWidth: "20px" }}>
              <Select
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={country}
                name="country"
                onChange={handleChange}
                inputProps={{
                  id: "open-select",
                }}
                MenuProps={langStyle.menuProps}
                sx={langStyle.sx}
              >
                {countries.map((option, key) => (
                  <MenuItem
                    name={"l"}
                    value={option.src}
                    key={key}
                    sx={langStyle.menuItem}
                  >
                    <img
                      width="30px"
                      height="30px"
                      src={option.src}
                      alt={option.label}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </nav>

          <ul className="flex flex-col items-center gap-4 mt-20 justify-center">
            <a href="" className="btn">
              Sign In
            </a>
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
