import { useTranslation } from "react-i18next";

const Hero = ({ title, subtitle, image, home }) => {
  const { t } = useTranslation();

  const notHomeClass =
    "padding grid grid-cols-1 lg:grid-cols-2 place-items-center gap-8 lg:-mt-20";
  const homeClass =
    "padding grid grid-cols-1 lg:grid-cols-2 place-items-center overflow-x-hidden overflow-hidden xl:overflow-visible gap-8";
  return (
    <section class={home ? homeClass : notHomeClass}>
      <div>
        <h1> {t(title)}</h1>
        <p> {t(subtitle)}</p>
      </div>
      {home ? (
        <div className="before:content-[url(/public/icons/hero-el.svg)] before:absolute relative before:-right-20 before:-top-16 before:z-0">
          <img src={image} alt="Hero Image" className="z-30 relative" />
        </div>
      ) : (
        <div className="">
          <img src={image} alt="Hero Image" className="z-30 relative" />
        </div>
      )}
    </section>
  );
};

export default Hero;
