import { useTranslation } from "react-i18next";

const TrademarksProgress = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-grey-100">
      <div className="padding gap-4 flex flex-col">
        <h3 className="mx-auto text-center">
          {t("trademarks.section-3.title")}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-16">
          <div className="border border-primary-100 py-8 px-8 space-y-3 relative">
            <h2 className="text-[#F7A441]/50 text-7xl md:text-8xl absolute right-3 bottom-full translate-y-1/2">
              1
            </h2>
            <img src="/images/lawyer.png" alt="icon" />
            <p className="text-black font-bold">
              {t("trademarks.section-3.block-1.title")}
            </p>
            <p>{t("trademarks.section-3.block-1.description")}</p>
          </div>
          <div className="border border-primary-100 py-8 px-8 space-y-3 relative">
            <h2 className="text-[#F7A441]/50 text-7xl md:text-8xl absolute right-3 bottom-full translate-y-1/2">
              2
            </h2>
            <img src="/images/application-drafting.png" alt="icon" />
            <p className="text-black font-bold">
              {t("trademarks.section-3.block-2.title")}
            </p>
            <p>{t("trademarks.section-3.block-2.description")}</p>
          </div>
          <div className="border border-primary-100 py-8 px-8 space-y-3 relative">
            <h2 className="text-[#F7A441]/50 text-7xl md:text-8xl absolute right-3 bottom-full translate-y-1/2">
              3
            </h2>
            <img src="/images/EUIPO.png" alt="icon" />
            <p className="text-black font-bold">
              {t("trademarks.section-3.block-3.title")}
            </p>
            <p>{t("trademarks.section-3.block-3.description")}</p>
          </div>
          <div className="border border-primary-100 py-8 px-8 space-y-3 relative">
            <h2 className="text-[#F7A441]/50 text-7xl md:text-8xl absolute right-3 bottom-full translate-y-1/2">
              4
            </h2>
            <img src="/images/10-year-validity.png" alt="icon" />
            <p className="text-black font-bold">
              {t("trademarks.section-3.block-4.title")}
            </p>
            <p>{t("trademarks.section-3.block-4.description")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrademarksProgress;
