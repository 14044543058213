import Home from "./pages/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Trademarks from "./pages/trademarks";
import Patents from "./pages/patents";
import ContractAgreement from "./pages/contract-agreement";

function App() {
  return (
    <div className="App scroll-smooth">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="trademarks" element={<Trademarks />} />
          <Route path="patents" element={<Patents />} />
          <Route path="contract-agreement" element={<ContractAgreement />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
