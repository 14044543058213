import Companies from "../sections/home/companies";
import Hero from "../components/hero";
import Perche from "../sections/home/perche";
import Plans from "../sections/home/plans";
import Principa from "../sections/home/principa";
import Nostri from "../sections/home/nostri";
import Layout from "../layouts";

const Home = () => {
  return (
    <Layout>
      <Hero
        title="hero.title"
        subtitle="hero.subtitle"
        image="/images/hero.png"
        home
      />
      <Nostri />
      <Principa />
      <Companies />
      <Perche />
      <Plans />
    </Layout>
  );
};

export default Home;
