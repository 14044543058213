import React from "react";

const Information = ({ title, paragraph }) => {
  return (
    <section className="bg-grey-100">
      <div className="padding flex flex-col">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <h3>{title}</h3>
          <div className="space-y-4">{paragraph}</div>
        </div>
      </div>
    </section>
  );
};

export default Information;
