import { useTranslation } from "react-i18next";

const Plans = () => {
  const { t } = useTranslation();

  return (
    <section className="padding gap-4 flex flex-col">
      <h3 className="mx-auto">{t("ourPlans.title")}</h3>
      <div className="flex flex-col gap-8">
        <div className="border border-primary-100">
          <div className="flex justify-between items-center bg-primary-100 px-8 py-4 relative">
            <div>
              <span className="text-white text-xl">
                {t("ourPlans.plans.plan-one.title")}
              </span>
            </div>
            <div>
              <span className="text-white text-3xl">€290,00 </span>
              <span className="text-2xl line-through text-[#C1C1C1]">
                €406,00{" "}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 px-8 pt-6 pb-10 gap-8">
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-one.features.feature1")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-one.features.feature2")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-one.features.feature3")}</p>
            </div>
          </div>
          <div className="px-8 pb-4 flex justify-end">
            <a
              href=""
              class="btn bg-[#F8AD53] text-black inline-flex items-center gap-2 hover:bg-[#F8AD53]/90 promo border-4 border-orange-100"
            >
              {t("buy-now")}
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="black"
              >
                <path
                  d="M0 7.72564C0 7.34595 0.282154 7.03215 0.648229 6.98249L0.75 6.97564L15.75 6.97564C16.1642 6.97564 16.5 7.31143 16.5 7.72564C16.5 8.10534 16.2178 8.41913 15.8518 8.4688L15.75 8.47564L0.75 8.47564C0.335786 8.47564 0 8.13986 0 7.72564Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M9.17101 2.23271C8.87749 1.94045 8.87646 1.46558 9.16872 1.17206C9.43442 0.905218 9.85103 0.880116 10.1451 1.09734L10.2294 1.16977L16.2794 7.19377C16.547 7.46026 16.5714 7.87837 16.3524 8.17245L16.2794 8.25667L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.5959 9.09817 13.3028L9.17096 13.2188L14.687 7.72494L9.17101 2.23271Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
        </div>

        <div className="border border-primary-100">
          <div className="flex justify-between items-center bg-primary-100 px-8 py-4">
            <div>
              <span className="text-white text-xl">
                {t("ourPlans.plans.plan-two.title")}
              </span>
              <div className="bg-white text-primary-100 px-2 text-xs font-bold grid place-items-center">
                <span className="uppercase text-xs">{t("best-selling")}</span>
              </div>
            </div>

            <div>
              <span className="text-white text-3xl">€590,00 </span>
              <span className="text-2xl line-through text-[#C1C1C1]">
                €1090,00{" "}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 px-8 pt-6 pb-10 gap-8">
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-two.features.feature1")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-two.features.feature2")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-two.features.feature3")}</p>
            </div>
            <div className="flex gap-3 items-start">
              <img src="/icons/checklist.svg" alt="" className="mt-0.5" />
              <p>{t("ourPlans.plans.plan-two.features.feature4")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-two.features.feature5")}</p>
            </div>

            <div className="flex gap-3 items-start">
              <img src="/icons/checklist.svg" alt="" className="mt-0.5" />
              <p>{t("ourPlans.plans.plan-two.features.feature7")}</p>
            </div>
          </div>
          <div className="px-8 pb-4 flex justify-end">
            <a
              href=""
              class="btn bg-[#F8AD53] text-black inline-flex items-center gap-2 hover:bg-[#F8AD53]/90 promo border-4 border-orange-100"
            >
              {t("buy-now")}
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="black"
              >
                <path
                  d="M0 7.72564C0 7.34595 0.282154 7.03215 0.648229 6.98249L0.75 6.97564L15.75 6.97564C16.1642 6.97564 16.5 7.31143 16.5 7.72564C16.5 8.10534 16.2178 8.41913 15.8518 8.4688L15.75 8.47564L0.75 8.47564C0.335786 8.47564 0 8.13986 0 7.72564Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M9.17101 2.23271C8.87749 1.94045 8.87646 1.46558 9.16872 1.17206C9.43442 0.905218 9.85103 0.880116 10.1451 1.09734L10.2294 1.16977L16.2794 7.19377C16.547 7.46026 16.5714 7.87837 16.3524 8.17245L16.2794 8.25667L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.5959 9.09817 13.3028L9.17096 13.2188L14.687 7.72494L9.17101 2.23271Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
        </div>

        <div className="border border-primary-100">
          <div className="flex justify-between items-center bg-primary-100 px-8 py-4">
            <div>
              <span className="text-white text-xl">
                {t("ourPlans.plans.plan-three.title")}
              </span>
            </div>
            <span className="text-white text-3xl">€2300,00 </span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 px-8 pt-6 pb-10 gap-8">
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-three.features.feature1")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-three.features.feature2")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-three.features.feature3")}</p>
            </div>
            <div className="flex gap-3 items-start">
              <img src="/icons/checklist.svg" alt="" className=" mt-0.5" />
              <p>{t("ourPlans.plans.plan-three.features.feature4")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-three.features.feature5")}</p>
            </div>
            <div className="flex gap-3 items-center">
              <img src="/icons/checklist.svg" alt="" />
              <p>{t("ourPlans.plans.plan-two.features.feature6")}</p>
            </div>
          </div>
          <div className="px-8 pb-4 flex justify-end">
            <a
              href=""
              class="btn bg-[#F8AD53] text-black inline-flex items-center gap-2 hover:bg-[#F8AD53]/90 promo border-4 border-orange-100"
            >
              {t("buy-now")}
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="black"
              >
                <path
                  d="M0 7.72564C0 7.34595 0.282154 7.03215 0.648229 6.98249L0.75 6.97564L15.75 6.97564C16.1642 6.97564 16.5 7.31143 16.5 7.72564C16.5 8.10534 16.2178 8.41913 15.8518 8.4688L15.75 8.47564L0.75 8.47564C0.335786 8.47564 0 8.13986 0 7.72564Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M9.17101 2.23271C8.87749 1.94045 8.87646 1.46558 9.16872 1.17206C9.43442 0.905218 9.85103 0.880116 10.1451 1.09734L10.2294 1.16977L16.2794 7.19377C16.547 7.46026 16.5714 7.87837 16.3524 8.17245L16.2794 8.25667L10.2294 14.2817C9.93593 14.574 9.46105 14.573 9.16877 14.2795C8.90305 14.0127 8.87971 13.5959 9.09817 13.3028L9.17096 13.2188L14.687 7.72494L9.17101 2.23271Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plans;
