import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Nostri = () => {
  const { t } = useTranslation();

  const cards = [
    {
      title: "nostri.card-one.title",
      subtitle: "nostri.card-one.subtitle",
      link: "/trademarks",
    },
    {
      title: "nostri.card-two.title",
      subtitle: "nostri.card-two.subtitle",
      link: "/contract-agreement",
    },
    {
      title: "nostri.card-three.title",
      subtitle: "nostri.card-three.subtitle",
      link: "/trademarks",
    },
    {
      title: "nostri.card-four.title",
      subtitle: "nostri.card-four.subtitle",
      link: "/patents",
    },
  ];

  return (
    <section className="bg-grey-100" id="service">
      <div className="padding flex flex-col">
        <div className="flex flex-col lg:flex-row">
          <h3>I nostri servizi</h3>
          <div className="swiper w-full">
            <div className="swiper-wrapper">
              {cards.map((card, index) => (
                <div className="card swiper-slide" key={index}>
                  <span>{t(card.title)}</span>
                  <p className="mt-12">{t(card.subtitle)}</p>
                  <Link className="mt-auto learnmore" to="/patents">
                    <div className="flex items-center gap-2">
                      {t("learn-more")}
                      <img className="" src="/icons/arrow.svg" />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4 mt-10 ml-auto mr-10">
          <div className="cursor-pointer prev">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-primary-100 hover:scale-125 transition-all"
            >
              <path
                d="M18.8333 19.5812H33.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.833 27.9161L5.60468 19.5811L18.833 11.2461V27.9161Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="cursor-pointer next">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-primary-100 rotate-180 hover:scale-125 transition-all"
            >
              <path
                d="M18.8333 19.5812H33.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.833 27.9161L5.60468 19.5811L18.833 11.2461V27.9161Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nostri;
